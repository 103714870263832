<template>
  <div>
    <modal-edit-tags
      v-if="showEditTags"
      :showModal="showEditTags"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="!isLocked"
      :editFunction="setShowEditTags"
      :editLabel="$t('Components.Reservation.ReservationDetail.Icon_EditTags')"
    >
      <template v-slot:title>{{ $t('Components.Reservation.ReservationDetail.Subtitle_Tags')}}</template>
    </ui-section-header>

    <message v-if="reservation.Tags.length === 0" :message="$t('Components.Reservation.ReservationDetail.Tag_MessageNoTagsAdded')" />

    <tags
      v-if="reservation.Tags.length > 0"
      :tags="reservation.Tags.split(',')"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

const Message = () => import('@/components/UI/Message')
const ModalEditTags = () => import('@/components/Reservations/ModalEditTags')
const Tags = () => import('@/components/UI/Tags')

export default {
  components: {
    message: Message,
    'modal-edit-tags': ModalEditTags,
    tags: Tags,
  },

  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      showEditTags: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    setShowEditTags() {
      this.showEditTags = true
    },

    hideModal() {
      this.showEditTags = false
    },
  },
}
</script>

<style></style>
